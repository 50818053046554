import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

export default function Loading() {
    return (
      <div className="">
        <div className="centered-axis-xy z-50" role="status">
          <Player
            src="/PCDL_LoadingAni.json"
            className="player h-12"
            loop
            autoplay
          />
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
}
