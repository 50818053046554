import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/react-in-jsx-scope */
export function Text({
 label, type, value, setValue, disabled, placeholder,
}) {
  return (
    <div>
      <label
        htmlFor={label}
        className="block font-semibold text-sm capitalize"
      >
        {label}
      </label>
      <div className="mt-1 flex rounded-md">
        <input
          type={type}
          name={label}
          min="1"
          id={label}
          className="block text-black border p-2 disabled:opacity-75 min-w-0 flex-1 rounded-md border-gray-300 focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
          onChange={(e) => setValue(e.target.value)}
          autoCapitalize="none"
          value={value}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

Text.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.string,
};

Text.defaultProps = {
  disabled: '',
};
