/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useDocumentTitle from '../../../components/useDocumentTitle';
import 'plyr-react/plyr.css';
import Registration from './Components/Registration';
import { Text } from '../../admin/components/fields/Text';
import { TextArea } from '../../admin/components/fields/TextArea';
import logoLt from '../../../assets/images/pcdl-logo-lt.png';

function Testimonies({ setView }) {
  useDocumentTitle('8DOM Testimonies | Pastor Chris Digital Library');

  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [registered, setRegistered] = useState(true);
  let splat = '';

  if (useParams()['*']) {
    splat = useParams()['*'];
  }

  const userId = JSON.parse(localStorage.getItem('pcdl.email'));

  const submitTestimony = async () => {
    if (name.trim() === '' || subject.trim() === '' || body.trim() === '') {
      setError(true);
    } else {
      const res2 = await fetch(
        'https://in2rxqmum9.execute-api.us-east-1.amazonaws.com/prod/8dom',
        {
          method: 'POST',
          headers: {
            'Access-Control-Request-Method': 'POST',
          },
          body: JSON.stringify({
            operation: 'testimonies',
            user_id: userId,
            name: name.trim(),
            subject: subject.trim(),
            body: body.trim(),
          }),
        },
      );
      const data2 = await res2.json();
      setBody('');
      setSubject('');
      setSubmitted(true);
      setError(false);
    }
  };

  return (
    <div className="flex justify-center md:items-center min-h-[80vh]">
      {!loading && (
        <div className="md:my-8 flex w-full justify-center">
          <div className="flex flex-col md:grid min-h-screen md:min-h-full grid-cols-1 md:grid-cols-9 gap-4 w-full md:rounded-md overflow-hidden max-w-[80rem] bg-neutral-800 shadow-md">
            <div className="md:col-span-4">
              <img
                alt="8dom"
                className="h-56 md:h-full w-full object-cover object-top"
                src="https://d1zx0zj5kmre28.cloudfront.net/images/covers/8dom.webp"
              />
            </div>
            <div className="md:col-span-5 px-8">
              {!submitted ? (
                <>
                  <img
                    alt="8dom"
                    className="w-32 md:mt-8 mt-4 mx-auto"
                    src={logoLt}
                  />
                  <p className="px-4 pt-6 text-white font-semibold">
                    You can share your testimony by filling in the form below.
                  </p>
                  {error && (
                    <p className="text-red-400 font-semibold mt-4">
                      Please ensure that no fields are left empty
                    </p>
                  )}
                  <div className="my-8 space-y-4 text-left text-white">
                    <Text
                      type="text"
                      label="Name"
                      value={name}
                      setValue={setName}
                    />
                    <Text
                      type="text"
                      label="Subject"
                      value={subject}
                      setValue={setSubject}
                    />
                    <TextArea
                      type="text"
                      label="Body"
                      value={body}
                      setValue={setBody}
                    />

                    <button
                      type="button"
                      onClick={() => submitTestimony()}
                      className="pcdl-pink-btn text-sm font-semibold mt-4 px-6 py-2 rounded-md"
                    >
                      Submit
                    </button>
                  </div>
                </>
              ) : (
                <div className="h-full flex flex-col md:justify-center md:items-center">
                  <img alt="8dom" className="w-56 my-4 mx-auto" src={logoLt} />
                  <p className="text-white font-bold">Glory to God! Thank you for sharing your testimony.</p>
                  <button
                    type="button"
                    onClick={() => setSubmitted(false)}
                    className="pcdl-pink-btn text-sm font-semibold mt-4 px-6 py-2 rounded-md"
                  >
                    Submit another
                  </button>
                  <a
                    href="/8dom"
                    className="pcdl-pink-btn text-sm font-semibold mt-4 px-6 py-2 rounded-md"
                  >
                    8 Days of Meditation
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Testimonies.propTypes = {
  setView: PropTypes.func.isRequired,
};

export default Testimonies;
