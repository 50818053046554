/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/react-in-jsx-scope */
export function TextArea({
 label, value, setValue, placeholder, 
}) {
  return (
    <div>
      <label
        htmlFor={label}
        className="block font-semibold text-sm capitalize"
      >
        {label}
      </label>
      <div className="mt-1 flex rounded-md">
        <textarea
          id={label}
          name={label}
          rows={3}
          placeholder={placeholder}
          className="block text-black border p-2 disabled:opacity-75 min-w-0 flex-1 rounded-md border-gray-300 focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      </div>
    </div>
  );
}

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};
