/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { Fragment, useEffect, useState } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import WordfestLogo from '../../../../assets/images/Wordfest_6_logo_d.webp';
import Loading from '../../../../components/misc/Loading';
import { Text } from '../../../admin/components/fields/Text';
import logo from '../../../../assets/images/pcdl-logo-blk.png';

const user_id = JSON.parse(localStorage.getItem('pcdl.email'));

export default function Registration({
  mainLoading,
  setMainLoading,
  status,
  setStatus,
  updated,
  setUpdated,
}) {
  const [open, setOpen] = useState(true);
  const [complete, setComplete] = useState(true);
  const [loading, setLoading] = useState(status);
  const [phone, setPhone] = useState('');
  const [query, setQuery] = useState('');
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState();
  const [selected2, setSelected2] = useState();
  const [query2, setQuery2] = useState('');
  const [zones, setZones] = useState([]);
  const [groups, setGroups] = useState([]);

  const filteredZones = query === ''
      ? zones
      : zones.filter((item) => item.zone_name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')));

  const filteredGroups = query2 === ''
      ? groups
      : groups.filter((item) => item.group_name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query2.toLowerCase().replace(/\s+/g, '')));

  const insertUser = async () => {
    setLoading(true);
    const response = await fetch(
      `https://api.ipstack.com/check?access_key=${process.env.REACT_APP_API_IPSTACK}&fields=country_name`,
    );
    const datax = await response.json();

    const res = await fetch(
      'https://in2rxqmum9.execute-api.us-east-1.amazonaws.com/prod/8dom',
      {
        method: 'POST',
        headers: {
          'Access-Control-Request-Method': 'POST',
        },
        body: JSON.stringify({
          operation: 'user_insert',
          user_id,
          first_name: JSON.parse(localStorage.getItem('pcdl.user')).first_name,
          last_name: JSON.parse(localStorage.getItem('pcdl.user')).last_name,
          location: datax.country_name,
          zone: selected ? selected.zone_id : 'na',
          zone_name: selected ? selected.zone_name : 'Not Present / Applicable',
          group: selected2 ? selected2.group_id : 'na',
          group_name: selected2
            ? selected2.group_name
            : 'Not Present / Applicable',
          zonal_code: JSON.parse(localStorage.getItem('pcdl.user')).affiliate,
        }),
      },
    );
    await res.json();
    setStatus(true);
    setLoading(false);
    window.location.reload();
  };

  const updateUser = async () => {
    if (selected !== undefined && selected !== null) {
      setLoading(true);
      const res = await fetch(
        'https://in2rxqmum9.execute-api.us-east-1.amazonaws.com/prod/wordfest/register',
        {
          method: 'POST',
          headers: {
            'Access-Control-Request-Method': 'POST',
          },
          body: JSON.stringify({
            operation: 'update',
            user_id,
            zone: selected.zone_id,
            zone_name: selected.zone_name,
          }),
        },
      );
      await res.json();
      setUpdated(true);
      setLoading(false);
    } else {
      setError(true);
    }
  };

  const getZones = async () => {
    const res = await fetch(
      'https://in2rxqmum9.execute-api.us-east-1.amazonaws.com/prod/admin',
      {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Request-Method': 'POST',
        },
        body: JSON.stringify({
          operation: 'zones',
        }),
      },
    );
    const data = await res.json();

    setZones(data.data);
  };

  const getGroups = async () => {
    const res = await fetch(
      'https://in2rxqmum9.execute-api.us-east-1.amazonaws.com/prod/admin',
      {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Request-Method': 'POST',
        },
        body: JSON.stringify({
          operation: 'groups',
          zone_id: selected.zone_id,
        }),
      },
    );
    const data = await res.json();

    setGroups(data.data);
  };

  useEffect(() => {
    getZones();
  }, []);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      setSelected2([]);
      setQuery2('');
      getGroups();
    }
  }, [selected]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative " onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 z-[99999] bg-opacity-90 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0  w-screen max-w-lg mx-auto overflow-y-auto z-[999999]">
          <div className="flex  min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div className="mx-auto flex items-center justify-center">
                  <img alt="" className="h-14" src={logo} />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    8 Days of Meditation
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Get Ready for an Indepth Study of the Year of Completeness
                    </p>
                  </div>
                </div>
                {!status && (
                  <>
                    <div className="mt-4">
                      <label
                        htmlFor="title"
                        className="block text-left text-xs font-medium text-gray-700"
                      >
                        Select Your Zone
                        {' '}
                        {/* <span className="text-pcdl-4">*</span> */}
                      </label>
                      <Combobox value={selected} onChange={setSelected}>
                        <div className="relative mt-1 text-left">
                          <div className="relative w-full cursor-default border rounded-lg bg-white rounded-[7px] border-blue-gray-200 outline outline-0 focus:outline-0 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                            <Combobox.Input
                              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                              displayValue={(item) => (item !== null ? `${item.zone_name}` : '')}
                              placeholder="Zone"
                              onChange={(event) => {
                                setQuery(event.target.value);
                              }}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </Combobox.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}
                          >
                            <Combobox.Options className="bottom-full shadow absolute mb-1 z-50 max-h-[10rem] w-full max-h-96 bg-white p-3 border border-blue-gray-50 rounded-md shadow-blue-gray-500/10 font-sans text-sm font-normal text-blue-gray-500 overflow-auto focus:outline-none">
                              {filteredZones.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                  Nothing found.
                                </div>
                              ) : (
                                filteredZones.map((item) => (
                                  <Combobox.Option
                                    key={item.zone_id}
                                    className={({ active }) => `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? 'bg-pcdl-4 text-white'
                                          : 'text-gray-900'
                                      }`}
                                    value={item}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected
                                              ? 'font-medium'
                                              : 'font-normal'
                                          }`}
                                        >
                                          {/* <span className="font-semibold">{item.zone_id}</span>
                                            {' '}
                                            -
                                            {' '} */}
                                          {item.zone_name}
                                        </span>
                                        {selected ? (
                                          <span
                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                              active
                                                ? 'text-white'
                                                : 'text-pcdl-4'
                                            }`}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))
                              )}
                            </Combobox.Options>
                          </Transition>
                        </div>
                      </Combobox>
                    </div>

                    <div className="mt-4">
                      <label
                        htmlFor="title"
                        className="block text-left text-xs font-medium text-gray-700"
                      >
                        Select Your Group
                        {' '}
                      </label>
                      <Combobox value={selected2} onChange={setSelected2}>
                        <div className="relative mt-1 text-left">
                          <div className="relative w-full cursor-default border rounded-lg bg-white rounded-[7px] border-blue-gray-200 outline outline-0 focus:outline-0 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                            <Combobox.Input
                              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                              displayValue={(item) => (item !== null ? selected2.group_name : '')}
                              placeholder="Group"
                              onChange={(event) => {
                                setQuery2(event.target.value);
                              }}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </Combobox.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery2('')}
                          >
                            <Combobox.Options className="bottom-full shadow absolute mb-1 z-50 max-h-[10rem] w-full max-h-96 bg-white p-3 border border-blue-gray-50 rounded-md shadow-blue-gray-500/10 font-sans text-sm font-normal text-blue-gray-500 overflow-auto focus:outline-none">
                              {filteredGroups.length === 0 && query2 !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                  Nothing found.
                                </div>
                              ) : (
                                filteredGroups.map((item) => (
                                  <Combobox.Option
                                    key={item.group_id}
                                    className={({ active }) => `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? 'bg-pcdl-4 text-white'
                                          : 'text-gray-900'
                                      }`}
                                    value={item}
                                  >
                                    {({ selected2, active }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected2
                                              ? 'font-medium'
                                              : 'font-normal'
                                          }`}
                                        >
                                          {item.group_name}
                                        </span>
                                        {selected2 ? (
                                          <span
                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                              active
                                                ? 'text-white'
                                                : 'text-pcdl-4'
                                            }`}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))
                              )}
                            </Combobox.Options>
                          </Transition>
                        </div>
                      </Combobox>
                    </div>
                  </>
                )}
                <div className="mt-5 sm:mt-6">
                  {!mainLoading ? (
                    !status ? (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-sm px-3 py-2 text-sm font-semibold text-white pcdl-pink-btn"
                        onClick={() => {
                          insertUser();
                        }}
                      >
                        Click to Participate
                      </button>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="pointer-events-none inline-flex w-full justify-center rounded-sm px-3 py-2 text-sm font-semibold bg-slate-200 text-slate-500 border-slate-200 shadow-none"
                        >
                          Registered
                        </button>
                        <p className="text-center text-xs mt-4 font-semibold text-pcdl-4">
                          See you soon!
                        </p>
                      </>
                    )
                  ) : (
                    <Loading />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
